import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 82, width: 186, mr: 2 }}>
      <svg
          viewBox="0 0 486.8 82.19"
        
        xmlns="http://www.w3.org/2000/svg"
      >
          <g id="s_logo_g">
              <g>
                  <path
                      d="M315.93,22.04c.08.39.73,1.12,1.09,1.39.9.69,5.22,3.47,6.14,3.8.99.35,2.71.71,3.76.8,7.12.62,12.43-2.82,13.06,6.81l-.84,32.47c3.95-3.52,7.71-7.57,8.23-13.07.48-5.09.68-20.92-.55-25.4-3.04-11.05-14.75-6.57-23.03-8.44l.38-14.41c-2.94-3.07-7.12-4.24-10.85-5.99-1.69,1.3-3.92,1.13-5.67,1.77s-3.67,2.72-5.73,3.17c-.15,2.24-.03,4.51-.06,6.76-.03,2.56-.27,5.2,0,7.85.32,3.11,1.71,5.87,3.93,8.07,2.84,2.81,9.12,4.59,9.97,8.66.3,1.42-.1,3.2.64,4.32.81,1.23,6.19,3.2,7.21,5.21.09-4.25,1.13-8.79.63-13.03s-8.57-6.83-11.61-9.49c-1.01-.89-2.48-2.79-2.48-4.14V7.36c.98.73,1.75,1.13,3.02.75l2.78-2.4v16.33Z"/>
                  <path
                      d="M285.9,21c-3.08.51-6.68,3.95-7.55,6.94-1.46,4.99-1.48,19.41-.33,24.49,1.04,4.61,4.52,10.58,9.88,10.35,2.34-2.48-.94-5.39-1.88-8-1.28-3.58-1.13-18.07-.77-22.42.42-4.96,3.44-3.88,7.44-3.87.31,3.58,1.81,6.19,3.77,9.05l.82-.24c-.25-1.29,1.25-3.57,1.25-4.51v-11.16l-.62-.62c-3.64.43-8.55-.58-12.01,0Z"/>
                  <path
                      d="M316.35,82.19c2.35-1.73,5.19-2.24,7.9-3.14.6-7.98-.39-13.86-5.61-20.02-5.92-6.98-15.99-11.12-21.14-17.35-1.67-2.01-4.39-9.65-6.61-9.77-3.42-.17-2.2,6.77-1.59,8.85,1.7,5.8,12.14,11.48,16.9,15.38,5.25,4.29,10.16,9.98,10.16,17.15v8.89Z"/>
                  <path
                      d="M335.82,31.34h-7.25c-1.42,0-.22,7-.19,8.04.33,12.33-.46,24.71.19,37.03l7.04-4.55.21-.62c.44-13.23.83-26.66,0-39.9Z"/>
                  <path
                      d="M288.18,46.64c-.13,3.37-.31,5.57,1.73,8.4,2.75,3.83,10.94,7.49,12.17,11.42.92,2.94-.94,11.5-.04,12.85l8.09,2.46c.08-2.96-.09-5.94-.01-8.91.05-2.01.7-3.94.44-6.19-.36-3.13-3.79-5.7-6.03-7.63-4.44-3.83-10.32-8.4-15.12-11.79-.4-.29-.49-.83-1.24-.62Z"/>
                  <path
                      d="M301.02,31.75v8.48c4.33,5.13,10.72,8.02,15.75,12.4,2.67,2.32,5.16,5.21,7.24,8.06.61-.4.58-.79.64-1.42.24-2.27.55-5.91-.33-7.98-.16-.38-2.58-3.14-3.04-3.58-3.08-2.94-7.17-5.04-10.51-7.7s-6.2-5.84-9.75-8.25Z"/>
                  <path d="M295.02,73.31c5.24-.12,4.05-7.88-.41-7.69s-5.03,7.82.41,7.69Z"/>
                  <path d="M330.31,17.2c4.55,1.03,6.38-4.12,3-6.3-4.54-2.93-7.98,5.17-3,6.3Z"/>
                  <path d="M293.86,17.19c6.7,1.53,4.48-9.42-.86-6.47-3.1,1.71-2.13,5.79.86,6.47Z"/>
              </g>
              <g>
                  <path
                      d="M4.22,46.49h.79c.41-1.78.79-3.57,1.14-5.38.35-1.81.53-3.61.53-5.41,0-1.09-.09-2.18-.28-3.27-.19-1.08-.49-2.15-.92-3.21l-.18-.44c.1-.06.22-.13.38-.21,1.41-.68,2.89-1.09,4.45-1.22,1.56-.13,3.11-.19,4.63-.19,2.5,0,4.99.02,7.47.06,2.48.04,4.96.06,7.44.06.62,0,1.37,0,2.24-.01.87,0,1.73-.04,2.58-.1s1.56-.17,2.12-.32c-.22,2.3-.9,3.89-2.05,4.75-1.15.86-2.83,1.29-5.04,1.29-2.81,0-5.62-.12-8.42-.35-2.8-.23-5.6-.51-8.39-.82-.08,2.54-.42,5.03-1.04,7.47-.62,2.44-1.22,4.87-1.8,7.29,2.19-.02,4.48-.06,6.88-.12s4.68-.16,6.84-.31c2.16-.15,3.95-.36,5.38-.63.16,1.15-.01,2.06-.51,2.72-.5.66-1.18,1.16-2.05,1.48-.87.32-1.82.53-2.84.62s-2,.13-2.92.13h-11.66c-.31,1.45-.62,2.93-.91,4.45s-.44,3.03-.44,4.51c0,1.7.29,3.32.86,4.85.58,1.53,1.63,2.88,3.15,4.03-.37.29-.86.64-1.46,1.04-.61.4-1.23.76-1.86,1.07-.63.31-1.19.47-1.66.47-1.89,0-3.19-.63-3.88-1.89-.69-1.26-1.04-2.82-1.04-4.67,0-1.5.13-3.11.4-4.8s.57-3.34.92-4.92.67-2.95.97-4.1c-1.41.02-2.74.04-4.01.06-.02-.14-.03-.32-.03-.56,0-1.09.43-1.94,1.29-2.53.86-.6,1.84-.89,2.93-.89Z"/>
                  <path
                      d="M42.63,40.54c-.61.29-1.32.58-2.15.85-.83.27-1.68.4-2.55.38-.87-.02-1.67-.33-2.42-.92-.74-.6-1.34-1.62-1.79-3.06,2.23.08,4.16-.04,5.8-.37s3.36-.48,5.16-.48c1.04,0,1.81.23,2.31.7.51.47.84,1.06,1,1.77.16.71.21,1.46.16,2.24-.05.78-.1,1.49-.16,2.14-.14,1.25-.39,2.72-.75,4.42-.36,1.7-.73,3.42-1.1,5.16-.14.61-.33,1.44-.57,2.49-.24,1.05-.45,2.18-.63,3.38s-.23,2.35-.16,3.44c.07,1.09.34,1.99.82,2.68.48.69,1.25,1.04,2.33,1.04.8,0,1.76-.03,2.87-.1s2.2-.24,3.25-.51,1.88-.71,2.49-1.32l.06-.06v.09c0,1.74-.51,3.09-1.52,4.06-1.02.97-2.27,1.65-3.75,2.04-1.48.39-2.94.59-4.37.59-2.36,0-4.15-.42-5.36-1.25-1.21-.83-2-1.93-2.37-3.3-.37-1.37-.47-2.88-.31-4.54.17-1.66.46-3.33.88-5.02.42-1.69.81-3.26,1.16-4.7.45-1.86.85-3.75,1.22-5.7.36-1.94.51-3.99.45-6.14ZM45.56,34.27c-1.21,0-2.11-.25-2.71-.76-.6-.51-.89-1.39-.89-2.64,0-.84.08-1.67.25-2.49.17-.82.36-1.63.57-2.43.74-.27,1.51-.51,2.3-.72s1.59-.31,2.39-.31c1.17,0,2.11.26,2.83.79.71.53,1.07,1.43,1.07,2.7,0,.86-.14,1.69-.41,2.5-.27.81-.58,1.61-.91,2.39-.72.27-1.46.5-2.2.69-.74.19-1.5.28-2.29.28Z"/>
                  <path
                      d="M73.42,43.09c0,1.72-.14,3.41-.41,5.08-.27,1.67-.57,3.34-.88,5.02,1.25-1.91,2.68-3.84,4.28-5.79,1.6-1.94,3.33-3.73,5.19-5.36,1.86-1.63,3.78-2.94,5.77-3.94s4-1.5,6.04-1.52c2.83-.02,4.82.78,5.98,2.39,1.15,1.61,1.73,3.73,1.73,6.34,0,2.15-.22,4.29-.67,6.42-.45,2.13-.67,4.27-.67,6.42,0,1.97.33,3.85.98,5.62.65,1.78,1.79,3.29,3.41,4.54-.37.27-.86.61-1.48,1s-1.25.74-1.89,1.04-1.2.45-1.67.45c-1.17,0-2.1-.3-2.8-.89-.69-.6-1.22-1.37-1.58-2.31-.36-.95-.6-1.94-.72-2.97s-.18-1.99-.18-2.87c0-1.93.18-3.78.54-5.55.36-1.77.73-3.45,1.1-5.04.37-1.59.56-3.09.56-4.5,0-1.07-.29-1.87-.86-2.39-.58-.52-1.29-.86-2.15-1.04-.86-.18-1.71-.26-2.55-.26-1.74,0-3.54.51-5.39,1.54-1.86,1.03-3.65,2.38-5.39,4.06-1.74,1.68-3.29,3.51-4.64,5.49-1.36,1.98-2.4,3.95-3.13,5.9-.73,1.95-1.03,3.69-.89,5.21.16.9.49,1.77,1.01,2.62.52.85,1.06,1.6,1.63,2.24-.78.2-1.58.37-2.39.53-.81.16-1.63.23-2.45.23-1.07,0-1.88-.31-2.42-.94-.54-.62-.89-1.4-1.07-2.31s-.26-1.8-.26-2.64c0-1.93.19-3.87.56-5.8.37-1.93.74-3.88,1.1-5.83.36-1.95.54-3.93.54-5.92,0-1.64-.2-3.23-.6-4.76-.4-1.53-1.14-2.94-2.21-4.23.68-.33,1.46-.65,2.33-.97.87-.31,1.67-.47,2.42-.47,1.17,0,2.06.31,2.67.94.6.63,1.02,1.41,1.23,2.36.21.95.32,1.9.32,2.86Z"/>
                  <path
                      d="M116.78,27.07c5.23,0,10.47.03,15.7.09,3.36.02,6.72.05,10.09.09,3.37.04,6.73.06,10.09.06h1.19c.4,0,.79.02,1.16.06-.18,1.95-.77,3.36-1.79,4.22-1.02.86-2.5,1.29-4.45,1.29-2.32,0-4.65-.05-6.99-.15-2.33-.1-4.65-.24-6.96-.44-.14,2.75-.56,5.45-1.27,8.1-.71,2.65-1.36,5.28-1.95,7.9-.35,1.72-.74,3.53-1.17,5.43-.43,1.9-.64,3.78-.64,5.64,0,1.7.29,3.32.86,4.85.58,1.53,1.63,2.88,3.15,4.03-.37.29-.86.64-1.46,1.04-.61.4-1.23.76-1.86,1.07-.63.31-1.19.47-1.66.47-1.89,0-3.19-.63-3.88-1.89-.69-1.26-1.04-2.82-1.04-4.67,0-1.41.12-2.9.35-4.5.23-1.59.52-3.14.85-4.64.33-1.5.65-2.86.95-4.06.3-1.2.53-2.12.69-2.77.45-2.07.91-4.16,1.38-6.27s.7-4.21.7-6.3c0-1.23-.11-2.39-.32-3.49-3.01.04-6.02.07-9.04.1s-6.02.34-9.01.92c.21-4.12,2.32-6.18,6.33-6.18Z"/>
                  <path
                      d="M179.33,63.72c0,1.5-.44,2.75-1.3,3.74-.87.99-1.97,1.76-3.31,2.31-1.34.56-2.73.95-4.19,1.17-1.46.22-2.77.34-3.94.34-2.89,0-5.61-.45-8.14-1.35-2.54-.9-4.6-2.34-6.17-4.34-1.57-1.99-2.36-4.62-2.36-7.88,0-1.86.29-3.7.86-5.54.58-1.84,1.34-3.54,2.3-5.13,2.09-3.38,4.76-6,8.01-7.85,3.25-1.86,6.86-2.78,10.83-2.78,1.48,0,3,.21,4.54.62,1.54.41,2.85,1.12,3.91,2.12,1.06,1.01,1.6,2.41,1.6,4.2,0,2.27-.61,4.19-1.83,5.79-1.22,1.59-2.83,2.91-4.83,3.94-2,1.04-4.19,1.83-6.55,2.39-2.36.56-4.71.93-7.05,1.13-2.33.2-4.43.25-6.28.18,0,.9.04,1.77.13,2.61.09.84.24,1.55.45,2.14.57,1.54,1.61,2.73,3.13,3.56s3.28,1.37,5.26,1.61c1.98.24,3.97.24,5.98-.01,2-.25,3.79-.71,5.36-1.36,1.57-.65,2.69-1.45,3.35-2.39.16.16.23.42.23.79ZM156.18,50.33c-.16.63-.3,1.37-.44,2.23,1.43.08,3.05.08,4.88.01,1.83-.07,3.67-.24,5.54-.53,1.86-.28,3.58-.69,5.16-1.23,1.57-.54,2.84-1.25,3.81-2.12s1.45-1.94,1.45-3.19c0-.88-.3-1.59-.91-2.14-.61-.55-1.35-.97-2.24-1.26-.89-.29-1.8-.5-2.72-.62-.93-.12-1.71-.18-2.36-.18-3.07,0-5.66.75-7.78,2.24-2.12,1.49-3.58,3.75-4.38,6.78Z"/>
                  <path
                      d="M202.62,71.28c-2.19,0-4.33-.24-6.43-.73-2.1-.49-3.99-1.27-5.67-2.36-1.68-1.08-3.02-2.5-4.01-4.26s-1.49-3.9-1.49-6.42c0-3.01.64-5.79,1.92-8.35,1.28-2.56,3.01-4.79,5.2-6.69,2.19-1.9,4.65-3.39,7.4-4.45,2.74-1.06,5.59-1.6,8.54-1.6.76,0,1.67.04,2.71.12,1.04.08,2.06.26,3.05.54.99.28,1.81.73,2.48,1.35.66.62,1,1.46,1,2.53,0,.55-.12,1.26-.35,2.14s-.6,1.48-1.08,1.79c-.88-1.05-1.98-1.84-3.3-2.34-1.32-.51-2.7-.84-4.15-1-1.45-.16-2.81-.23-4.1-.23-4.12,0-7.43,1.2-9.92,3.59-2.49,2.39-3.74,5.69-3.74,9.89,0,2.77.6,5.01,1.8,6.72,1.2,1.71,2.83,2.96,4.88,3.75s4.34,1.19,6.86,1.19,4.44-.2,5.82-.59,2.42-.85,3.12-1.39,1.28-1.04,1.73-1.51c.27,1.54.09,2.85-.54,3.91-.63,1.06-1.57,1.92-2.8,2.56s-2.63,1.11-4.19,1.41c-1.56.29-3.13.44-4.72.44Z"/>
                  <path
                      d="M230.07,70.02c-.76.1-1.52.25-2.29.47s-1.53.32-2.31.32c-1.13,0-1.95-.32-2.46-.95-.51-.63-.83-1.42-.95-2.34-.13-.93-.19-1.82-.19-2.68,0-2.21.26-4.39.79-6.56s1.06-4.32,1.61-6.45c.7-2.79,1.36-5.6,1.96-8.41.6-2.81.91-5.66.91-8.55,0-1.6-.17-3.21-.5-4.82-.33-1.61-.99-3.07-1.96-4.38.66-.33,1.41-.64,2.24-.94.83-.29,1.61-.44,2.33-.44,1.19,0,2.07.32,2.64.97s.93,1.45,1.1,2.4c.17.96.25,1.9.25,2.84,0,2.81-.33,5.58-1,8.31s-1.42,5.43-2.27,8.13-1.56,5.41-2.12,8.14c1.19-1.97,2.56-4.03,4.12-6.17s3.23-4.14,5.02-6.01c1.8-1.86,3.66-3.38,5.58-4.54,1.92-1.16,3.85-1.75,5.79-1.77,2.83-.02,4.82.78,5.98,2.39s1.73,3.73,1.73,6.34c0,2.15-.22,4.29-.67,6.42-.45,2.13-.67,4.27-.67,6.42,0,1.97.33,3.85.98,5.62.65,1.78,1.79,3.29,3.41,4.54-.37.27-.86.61-1.48,1s-1.25.74-1.89,1.04-1.2.45-1.67.45c-1.17,0-2.1-.3-2.8-.89s-1.22-1.37-1.58-2.31-.6-1.94-.72-2.97-.18-1.99-.18-2.87c0-1.93.18-3.78.54-5.55.36-1.77.73-3.45,1.1-5.04.37-1.59.56-3.09.56-4.5,0-1.07-.29-1.87-.86-2.39-.58-.52-1.29-.86-2.15-1.04s-1.71-.26-2.55-.26c-1.66,0-3.31.55-4.95,1.64-1.64,1.09-3.21,2.52-4.7,4.28s-2.85,3.66-4.07,5.71-2.23,4.03-3.03,5.95c-.27.66-.51,1.33-.7,1.99-.2.66-.29,1.33-.29,1.99,0,1.02.24,1.99.72,2.93.48.94,1.04,1.79,1.68,2.55Z"/>
              </g>
              <g>
                  <path
                      d="M369.64,60.98c0-2.97.43-5.9,1.29-8.8s1.62-5.77,2.29-8.6c.45-1.97.84-3.95,1.17-5.93s.5-3.98.5-5.99c0-1.54-.17-3.09-.51-4.63s-.98-2.94-1.92-4.19c.66-.33,1.43-.65,2.29-.97s1.65-.47,2.37-.47c1.66,0,2.78.54,3.35,1.63s.86,2.34.86,3.76c0,2.77-.39,5.58-1.17,8.42s-1.46,5.63-2.02,8.36c0,.02,0,.04-.01.06s-.01.04-.01.06c-.51,2.42-1.04,4.86-1.61,7.32s-.85,4.94-.85,7.44c0,.72.02,1.38.06,1.96,2.42.1,4.84.16,7.25.19s4.83.04,7.25.04h2.5c1.03,0,2.1-.02,3.24-.06s2.19-.11,3.18-.21,1.75-.23,2.3-.41c-.2,1.95-.73,3.37-1.6,4.26s-1.98,1.45-3.33,1.68-2.84.35-4.48.35c-2.25,0-4.5-.1-6.75-.29s-4.52-.41-6.78-.64c-.25-.04-.53-.08-.82-.12s-.55-.08-.76-.12c-1.02.7-2.08,1.05-3.19,1.05-1.6,0-2.68-.52-3.24-1.55s-.83-2.25-.83-3.63Z"/>
                  <path
                      d="M435.73,55.97v-5.39c-.72,1.05-1.62,2.27-2.7,3.65s-2.29,2.78-3.65,4.2-2.82,2.74-4.38,3.96-3.19,2.19-4.89,2.94-3.42,1.13-5.16,1.13c-2.48,0-4.52-.58-6.11-1.74s-2.77-2.71-3.53-4.63-1.14-4.02-1.14-6.28c0-3.03.53-5.87,1.58-8.54s2.54-5.01,4.45-7.05,4.17-3.62,6.78-4.78,5.46-1.73,8.57-1.73c1.46,0,2.99.2,4.58.59s3.1.9,4.51,1.54,2.59,1.33,3.53,2.09c.29-1.02.69-1.89,1.19-2.64s1.17-1.11,2.01-1.11c.55,0,1.1.14,1.67.42s1.06.57,1.49.86c-1.31,2.36-2.17,5-2.59,7.91s-.63,5.7-.63,8.38c0,1.91.11,3.66.34,5.23s.69,3.05,1.41,4.44,1.78,2.77,3.21,4.16c-.55.43-1.3.96-2.27,1.58s-1.8.94-2.5.94c-1.29,0-2.31-.34-3.08-1.01s-1.34-1.54-1.73-2.61-.65-2.18-.78-3.34-.19-2.22-.19-3.18ZM409.75,50.72c0,1.74.27,3.32.81,4.73s1.39,2.54,2.56,3.37,2.71,1.25,4.63,1.25c1.58,0,3.18-.43,4.79-1.29s3.17-1.99,4.69-3.4,2.92-2.94,4.22-4.6,2.43-3.31,3.38-4.94,1.68-3.08,2.17-4.35c.08-.39.16-.78.25-1.17s.17-.79.25-1.2c-.98-.84-2.13-1.46-3.46-1.86s-2.71-.66-4.16-.78-2.81-.18-4.1-.18c-2.97,0-5.66.55-8.09,1.66s-4.35,2.73-5.79,4.88-2.15,4.78-2.15,7.88Z"/>
                  <path
                      d="M464.8,29.6c-.64,3.24-1.29,6.41-1.93,9.51s-1.27,6.26-1.88,9.51c.41-.96.78-1.77,1.11-2.45s.72-1.39,1.17-2.15c.61-1.02,1.39-2.21,2.34-3.59s2.04-2.73,3.24-4.06,2.48-2.43,3.82-3.31,2.72-1.32,4.13-1.32c2.01,0,3.67.42,4.97,1.25s2.31,1.93,3.05,3.3,1.25,2.86,1.54,4.47.44,3.2.44,4.76c0,2.52-.36,5.02-1.07,7.5s-1.78,4.74-3.21,6.77-3.21,3.65-5.35,4.86-4.62,1.82-7.46,1.82c-1.91,0-3.7-.41-5.35-1.23s-3.25-1.7-4.79-2.64c-.18.76-.47,1.55-.89,2.36s-1.05,1.22-1.89,1.22c-.51,0-1.02-.13-1.54-.4s-.97-.55-1.36-.86c.84-3.81,1.57-7.64,2.18-11.5s1.21-7.72,1.77-11.59c.35-2.36.67-4.73.97-7.09s.44-4.74.44-7.12-.66-4.66-1.99-6.59c.62-.33,1.33-.65,2.12-.97s1.55-.47,2.27-.47c1.5,0,2.5.52,3,1.55s.75,2.19.75,3.46c0,.84-.06,1.68-.19,2.52s-.27,1.67-.42,2.49ZM475.08,38.1c-1.56,0-3.04.56-4.42,1.67s-2.67,2.55-3.84,4.31-2.21,3.62-3.12,5.58-1.67,3.81-2.27,5.55-1.04,3.13-1.32,4.19c1.39.68,2.92,1.27,4.58,1.76s3.25.73,4.73.73c2.66,0,4.87-.62,6.65-1.85s3.12-2.87,4.01-4.92,1.35-4.31,1.35-6.77c0-1.04-.09-2.14-.26-3.33s-.49-2.29-.94-3.34-1.09-1.9-1.92-2.58-1.91-1.01-3.24-1.01Z"/>
              </g>
          </g>
      </svg>
    </SvgIcon>
  );
}
