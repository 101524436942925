import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/system';
import Grid from "@mui/material/Grid";


export default function Features() {
  const theme = useTheme();
  
  return (
        <Box
              id="features" sx={{
          pt: { xs: 4, sm: 30 },
          pb: { xs: 8, sm: 30 },
          color: 'white',
          bgcolor: 'hsl(210,36%,96%)',
        }}>
          <Container
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: { xs: 4, sm: 8 },
                }}
          >
            <Box
                  sx={{
                    width: { sm: '100%'},
                    textAlign: { sm: 'left', md: 'center' },
                  }}
            >
                <Typography component="h2" variant="h3" sx={{ color: 'hsl(201,100%,36%)' }}>
                    Features
                </Typography>
                <br/>
                
                <Grid container spacing={3} sx={{
                    textAlign: { sm: 'left', md: 'left' },
                }}>
                    <Grid item xs={4}>
                        <img src={"/features.webp"} width={"100%"}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography component="h2" variant="h5" sx={{ color: 'hsl(210,11%,15%)', whiteSpace: 'pre-wrap' }}>
                            Fintech Lab technologies enabled the global detection, categorisation, identification, and prevention of fraudulent real estate and finance activities.
                            In many circumstances, you only need to receive the proper signal at the right moment.
                            If something seems too good to be true, it probably is. Using our solutions will not only save you money, but also provide you peace of mind.
                            Fintech Lab solutions provide the ideal fraud protection solution for its clients globally, safeguarding not only your money but also your security, including your personal and confidential information in financial institutions.
                        </Typography>
                    </Grid>
                </Grid>
              
            </Box>
          </Container>
        </Box>
  );
}
