import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {ListItemIcon, ListItemText} from "@mui/material";


export default function Benefits() {
  const theme = useTheme();
  
  return (
        <Box
              id="benefits" sx={{
          pt: { xs: 4, sm: 20 },
          pb: { xs: 8, sm: 20 },
          color: 'hsl(0,0%,92%)',
            bgcolor: 'hsl(201,100%,36%)',
        }}>
          <Container
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: { xs: 4, sm: 8 },
                }}
          >
            <Box
                  sx={{
                    width: { sm: '80%'},
                    textAlign: { sm: 'left', md: 'center' },
                  }}
            >
                <Typography component="h2" variant="h3">
                    Ten Golden Rules
                </Typography>
                
                <Typography component="h2" variant="h5">
                    <List dense={false} >
                        <ListItem>
                            <ListItemText
                                  disableTypography={true}
                                  primary={"1. Be suspicious of all ‘too good to be true’ offers and deals. There are no guaranteed get-rich-quick schemes."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                  disableTypography={true}
                                  primary={"2. Don’t agree to offers or deals immediately. Insist on time to get independent or legal advice before making a decision."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                  disableTypography={true}
                                  primary={"3. Don’t hand over money or sign anything until you’ve checked someone’s credentials and their company’s."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                  disableTypography={true}
                                  primary={"4. Never send money to anyone you don’t know or trust, whether in the UK or abroad, or use methods of payment you’re not comfortable with."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                  disableTypography={true}
                                  primary={"5. Never give banking or personal details to anyone you don’t know or trust. This information is valuable so make sure you protect it."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                disableTypography={true}
                                primary={"6. Always log on to a website directly rather than clicking on links in an email."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                disableTypography={true}
                                primary={"7. Don’t just rely on glowing testimonials. Find solid, independent evidence of a company’s success."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                disableTypography={true}
                                primary={"8. Always get independent or legal advice if an offer involves money, time or commitment."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                disableTypography={true}
                                primary={"9. If you spot a scam or have been scammed, report it and get help."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                disableTypography={true}
                                primary={"10. Do not feel embarrassed about reporting a scam. Fraudsters are skilled and creative, there is no shame for being deceived. By reporting it, you make it harder for them to take advantage of others.​"}
                            />
                        </ListItem>
                    </List>
                </Typography>
              
            </Box>
          </Container>
        </Box>
  );
}
