import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import Person4Icon from '@mui/icons-material/Person4';
import LanguageIcon from '@mui/icons-material/Language';
import ApartmentIcon from '@mui/icons-material/Apartment';
import NfcIcon from '@mui/icons-material/Nfc';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';


const items = [
  {
    icon: <Diversity1Icon fontSize={"large"}/>,
    title: 'Romance fraud',
    description:
      'Have you seen the person face to face (video call or in person)? \n' +
        'Are they asking for regular or larger sums of money? \n' +
        'Are they urging you to keep your relationship secret? \n' +
        'Does their story make sense? \n' +
        'Are they making unusual money or foreign transfers? \n',
  },
  {
    icon: <MonetizationOnIcon fontSize={"large"}/>,
    title: 'Investment Fraud',
    description:
      'Fraudsters rely on your failure to do adequate research before making an investment. Do your own digging to ward them off. References or additional information received from the person and/or company may be fraudulent as well.',
  },
  {
    icon: <Person4Icon fontSize={"large"}/>,
    title: 'Impersonation Schemes',
    description:
      'Fraudsters often impersonate organizations or individuals to lure victims into scams. They may impersonate representatives of government agencies or legitimate investing professionals like brokers and advisers.',
  },
    {
        icon: <LanguageIcon fontSize={"large"}/>,
        title: 'Internet and Social Media Fraud',
        description:
            'Messages claiming to be from celebrities, especially if they’re endorsing cryptocurrency or asking for money. Social-media adverts promising easy jobs at excellent salaries when you pay an ‘administration fee’',
    },
    {
        icon: <ApartmentIcon fontSize={"large"}/>,
        title: 'Affinity Fraud',
        description:
            'It is typical for the fraudsters to pose as group members. They might recruit well-known group leaders to persuade family or friends to join the scheme. It frequently incorporates "Ponzi" or pyramid schemes, in which funds from new investors are used to reimburse those from previous investors, giving the impression that the investment was profitable.',
    },
    {
        icon: <DatasetLinkedIcon fontSize={"large"}/>,
        title: 'Invoice or Mandate Fraud',
        description:
            'Receiving duplicate invoices, round-figure amounts without detailed breakdowns, invoices with unjustified urgent payment requests, sudden changes in the bank account information, and discrepancies between purchase orders and invoices.',
    },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 20 },
        pb: { xs: 8, sm: 20 },
        color: 'white',
        bgcolor: 'hsl(201,100%,36%)',
      }}
    >
        <Typography component="h2" variant="h3" sx={{ color: 'hsl(0,0%,92%)' }}>
            Fintech Lab automatically detects and prevents the following payment fraud topologies.
        </Typography>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 4 },
            marginTop: '30px',
        }}
      >
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={2}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsl(208,7%,46%)',
                  background: 'transparent',
                  backgroundColor: 'hsla(190,100%,42%,0.2)',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ color: 'hsl(0,0%,92%)', opacity: '80%'}}>{item.icon}</Box>
                <div>
                  <Typography component="h2" variant="h5" gutterBottom sx={{ color: 'hsl(0,0%,92%)', fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                    <br/>
                  <Typography component="h2" variant="h6" sx={{ color: 'hsl(0,0%,92%)', whiteSpace: 'pre-wrap' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
